<template>
    <searchBody>
        <search :show-create="true" class="searchbar" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search>
        <Card class="table_container" ref="table_container">
            <Table stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                <template slot-scope="{ row }" slot="pic">
                    <previewImage :thumWidth="50" :thumHeight="50" :src="row.pic"></previewImage>
                </template>
                <template slot-scope="{ row }" slot="groupid">
                    {{ (roleList.find(role => role.id == row.groupid) || {}).groupname || row.groupid || '-' }}
                </template>
                <template slot-scope="{ row }" slot="status">
                    <span :class="'now_state_' + row.status">{{ { 0: '关闭', 1: '启用' }[row.status] }}</span>
                </template>
                <template slot-scope="{ row }" slot="manager">
                    <div class="table_manager flex flex_wrap align_center">
                        <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                        <Button type="error" size="small" ghost @click="onDelete(row)">删除</Button>
                    </div>
                </template>
            </Table>
        </Card>
        <div class="relative width_100_percent text_align_right">
            <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>
    </searchBody>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import previewImage from '@/components/previewImage';
import { reqBannerList, reqDelBanner } from '@/lib/request/auth';

export default {
    name: 'accountList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
        previewImage,
    },
    data() {
        return {
            roleList: [],
            searchs: [
                { id: hm.createId(), label: '编号', placeholder: '输入编号', type: 'input', bind: 'id' },
                { id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'title' },
                {
                    id: hm.createId(),
                    label: '状态',
                    placeholder: '选择状态',
                    type: 'select',
                    bind: 'status',
                    list: [
                        { id: '1', title: '启用' },
                        { id: '0', title: '关闭' },
                    ],
                },
            ],
            dataColumn: [
                { key: 'id', title: 'ID', minWidth: 80, tooltip: true },
                { key: 'title', title: '名称', minWidth: 70, tooltip: true },
                { slot: 'pic', title: '图片', minWidth: 80, tooltip: true },
                { slot: 'status', title: '状态', minWidth: 60 },
                { slot: 'manager', title: '操作', minWidth: 130 },
            ],
            dataList: [],
        };
    },
    mounted() {
        this.getList();
    },
    methods: {
        //获取列表数据
        getList() {
            this.showAjaxLoading();
            reqBannerList(this.params)
                .then(res => {
                    this.dataInjectDataList(res);
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(_row) {
            this.goUrl('/bannerAdd', {
                id: (_row || {}).id || null,
            });
        },
        //删除
        onDelete(info = {}) {
            let { id, title } = info || {};
            this.confirmDialog(`确认删除【${title}】吗`).then(isOK => {
                if (isOK == 1) {
                    this.showAjaxLoading();
                    reqDelBanner({ id })
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.getList();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                }
            });
        },
    },
};
</script>
